import React, { useEffect, useState } from "react";
// import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";
import FourOFour from "./components/FourOFour";
import useWidth from "./hooks/useWidth";
import ClientHasAYetToShippedOrder from "./components/ClientHasAyetToShippedOrder/ClientHasAYetToShippedOrder";
import RedirectShortLinks from "./components/ShortLinks/RedirectShortLinks";
import TopBar from "./screens/AdminTopBar/TopBar";
import { useGetBrowserFingerPrint } from "./hooks/useGetBrowserFingerPrint";
import { useSaveFingerPrintInDatabase } from "./hooks/useSaveFingerPrintInDatabase";
import { useGetUserFromStore } from "./hooks/useGetUserFromStore";
import { useCalculateCartTotal } from "./hooks/useCalculateCartTotal";
import { useSelector } from "react-redux";
import { useBrowserStorage } from "./hooks/useBrowserStorage";
import { useGetIsPendingFromStore } from "./hooks/useGetIsPendingFromStore";
import AppUser from "./components/App/AppUser";
import AppAdminUser from "./components/App/AppAdminUser";
import AppWholeSaleUser from "./components/App/AppWholeSaleUser";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

/*function usePageViews() {
  let location = useLocation();
  useEffect(() => {
    ReactGA.initialize("UA-205276615-1");
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  }, [location]);
}*/

function App() {
  const location = useLocation();
  const pathname = location.pathname;
  const isAdminPanel = pathname.includes("admin-panel");
  const width = useWidth();

  // DETECT IF CUSTOMER HAS AN ORDER OR TOP BAR IS ACTIVE
  const [hasAnOrder, setHasAnOrder] = useState("");
  const [isTopBarActive, setIsTopBarActive] = useState(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const orderId = localStorage.getItem("tChAo");
    const topBar = sessionStorage.getItem("topBar");
    setHasAnOrder(orderId);

    if (topBar) {
      setIsTopBarActive(true);
    }
  });

  // GET USER and ORDER, fingerPrint visitor ID AND SAVE IN DATABASE
  const user = useGetUserFromStore();
  const { visitorId, errMessage } = useGetBrowserFingerPrint(); // This hook gets fingerPrint from API and save in sessionStorage

  const createdFingerPrint = useSaveFingerPrintInDatabase(
    visitorId,
    user,
    errMessage
  );

  useBrowserStorage(
    createdFingerPrint?.firstVisitTime,
    "session",
    "set",
    "visitTime"
  );

  //REDUX STATE THAT HOLDS CART ITEMS
  const addOrRemoveSkuItemInCart = useSelector(
    (state) => state.addOrRemoveSkuItemInCart
  );
  const { cartSkuItems } = addOrRemoveSkuItemInCart;

  // REDUX STATE THAT HOLDS INFO IF THERE IS A PENDING ORDER OF CLIENT
  const isPending = useGetIsPendingFromStore();
  const cartTotal = useCalculateCartTotal(
    cartSkuItems,
    createdFingerPrint?.firstVisitTime,
    isPending
  );

  return (
    <>
      {location.pathname.includes("toptan") ? (
        ""
      ) : (
        <>
          <ClientHasAYetToShippedOrder orderId={hasAnOrder} />
          <TopBar
            hasAnOrder={hasAnOrder}
            setIsTopBarActive={setIsTopBarActive}
            createdFingerPrint={createdFingerPrint}
          />
        </>
      )}
      <GoogleReCaptchaProvider reCaptchaKey="6LdwtRQpAAAAAHEXfNu75NKrCxpIIWSehVqQtFPq">
        {location.pathname.includes("toptan") &&
        !location.pathname.includes("admin-panel") ? (
          <AppWholeSaleUser />
        ) : (
          <main
            style={{
              paddingTop:
                !location.pathname.includes("admin") &&
                width > 800 &&
                (hasAnOrder || isTopBarActive)
                  ? "4rem"
                  : "",
            }}
          >
            <AppUser
              hasAnOrder={hasAnOrder}
              isTopBarActive={isTopBarActive}
              createdFingerPrint={createdFingerPrint}
              cartTotal={cartTotal}
              isPending={isPending}
              RedirectShortLinks={RedirectShortLinks}
              FourOFour={FourOFour}
            />

            <AppAdminUser isAdminPanel={isAdminPanel} />
          </main>
        )}
      </GoogleReCaptchaProvider>
    </>
  );
}

export default App;
